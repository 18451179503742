.loading-overlay {
    pointer-events: all;
    z-index: 99999;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    cursor: wait;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
   }