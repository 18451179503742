

  :root {
    --accent-color: #71BF44;
    --foreground-color: #0F1419;
    --background-color: white;
    --gray-dark-color: #66757F;
    --gray-light-color: #edeff1;
  }


/* .login-page {      
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
} */

.login-page-main {
  overflow: hidden!important;
  background-color: var(--accent-color);
  background-image: url('../assets/images/form-login-bg-1.png');
  /* background-position-x: right; */
  background-size:50%; /* Resize the background image to cover the entire container */
} 

.login-page-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--accent-color); */
  /* color: var(--background-color); */
  /* padding-right: 0; */
  height: 100vh;
  /* border: none; */
  /* background: rgba(0, 0, 0, 0.6); */
  background-image: url("../assets//images/signin-bg-1.png"); 

  background-position:  center ; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  
  background-size: cover; /* Resize the background image to cover the entire container */
}


/* .login-page-hero .logo-wrap {
 margin-block: 4vh;
  text-align: center;
} */

/* .login-page-hero .slide-show-wrap{
  width: 100%;
} */

.login-page-hero > img {
 height: 60vh;
 
}



.login-form {
  display: flex;

}

.login-form > .ant-form {
  margin: auto;
  width: 80%;
  }

  .form-input-login {
    border-radius: 0px 50px 50px 50px;
  }

.login-form-button, .login-form-button:hover, .login-form-button:focus {
width: 100%;
background-color: white;
color: var(--accent-color);
border-color: white;
font-weight: bold;
border-radius: 50px 50px 0px 50px;
}

.text-sigin {
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
}

@media (max-width: 992px) {
  .login-form > .ant-form {
   margin-top: 10vh;
    min-height: 40vh;
  }
}




