* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
.container {      
    height: 100%;
    width: 100%;
    /* overflow-y: auto!important; */
    /* overflow-x: hidden!important;      */
}

.ant-layout-header {
  background-color: #00796b;
}



@media (max-width: 976px) {
  .container {      
    overflow-y: auto;   
  
}
}

@media (max-width: 576px) {

  /* Top bar */
  ul.gx-header-notifications  li.display-now {
    display: none;
  }
}
