.modal-select-gate {
    width: 80vw!important;
}

.modal-select-gate .ant-modal-header {
    border: none!important;  
}

.modal-select-gate .ant-modal-close {
    display: none;
}

/* .gate-label {
    font-size: 2rem;
}

.gate-label:hover, .gate-label::selection {
   color: #038fde;
} */


.gate-option {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: auto auto ;
    
}

.gate-option .ant-radio-button-wrapper {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
}

.gate-option .ant-radio-button-wrapper:hover {
    border: 1px solid #038fde;
   
}

@media (max-width: 992px) {
    .gate-option {      
        grid-template-columns: auto auto;
    }
  }

  @media (max-width: 576px) {
    .gate-option {      
        grid-template-columns: auto ;
  
    }
  }
  