.each-slide-effect > div {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background-size: cover;
    height: 70vh;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    /* background: #efefef; */
    display: none;
    text-align: center;
  }